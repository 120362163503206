import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import ChatbotSvgIcon from "@/assets/icons/chatbot.svg";

import styles from "./SalesforceMessaging.module.scss";

const SalesforceMessaging = () => {
  const router = useRouter();
  const [isChatbotReady, setIsChatbotReady] = useState(false);
  const showChatbotIcon =
    [
      "/",
      "/clubs/[region]/[subregion]",
      "/clubs/[region]/[subregion]/[clubName]",
    ].includes(router.pathname) ||
    ["/faq", "/memberbenefits"].includes(router.asPath?.split("?")[0]);

  useEffect(() => {
    if (showChatbotIcon) {
      window.initEmbeddedMessaging(setIsChatbotReady);
    } else {
      const minimizedIcon =
        document.getElementsByClassName("embedded-messaging")[0];

      if (minimizedIcon) {
        minimizedIcon.style.display = "none";
      }
    }
  }, [showChatbotIcon]);

  const launchChat = () => {
    window.embeddedservice_bootstrap.utilAPI.launchChat().then(() => {
      const minimizedIcon =
        document.getElementsByClassName("embedded-messaging")[0];

      if (minimizedIcon) {
        minimizedIcon.style.display = "block";
      }
    });
  };

  return (
    showChatbotIcon &&
    isChatbotReady && (
      <div className={styles.chatbotContainer} onClick={launchChat}>
        <ChatbotSvgIcon />
      </div>
    )
  );
};

export default SalesforceMessaging;
